import React, { Component } from "react"
import Slide from "react-awesome-reveal"

class Services extends Component {
  render() {
    if (!this.props.data) return null

    const services = this.props.data.ourservices

    const card = services.map((service) => {
      return (
        <div className="four columns" key={service.title}>
          <div className="card">
            <h3>{service.title}</h3>
            <div className="description-container">
              <p className="description">{service.description}</p>
            </div>

            <button>
              <a href="#contactus">Contact</a>
            </button>
          </div>
        </div>
      )
    })

    return (
      <section id="services">
        <Slide left duration={1300} triggerOnce="true">
          <div className="row">
            <div className="header-col service-header">
              <h1>
                <span>Services</span>
              </h1>
            </div>

            <div className="row">
              <div className="row item">
                <div className="card-container">{card}</div>
              </div>
            </div>
          </div>
        </Slide>
      </section>
    )
  }
}

export default Services
