import React, { Component } from "react"
import $ from "jquery"
import "./App.css"
import Header from "./Components/Header"
import Footer from "./Components/Footer"
import About from "./Components/About"
import Services from "./Components/Services"
import Cases from "./Components/Cases"
import Career from "./Components/Career"
import Contactus from "./Components/Contactus"
//import Resume from "./Components/Resume";
//import Contact from "./Components/Contact"
//import Portfolio from "./Components/Portfolio"

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: {},
    }
  }

  getData() {
    $.ajax({
      url: "./data.json",
      dataType: "json",
      cache: false,
      success: function (data) {
        this.setState({ data })
      }.bind(this),
      error: function (xhr, status, err) {
        console.log(err)
        alert(err)
      },
    })
  }

  componentDidMount() {
    this.getData()
  }

  render() {
    return (
      <div className="App">
        <Header data={this.state.data.main} />
        <About data={this.state.data.main} />
        <Services data={this.state.data.services} />
        <Cases data={this.state.data.cases} />
        <Career data={this.state.data.career} />
        {/* <Portfolio data={this.state.data.portfolio} /> */}
        <Contactus data={this.state.data.contact} />
        <Footer data={this.state.data.main} />
      </div>
    )
  }
}

export default App
