import React, { Component } from "react"
import Slide from "react-awesome-reveal"

class Career extends Component {
  render() {
    if (!this.props.data) return null

    const { part1, part2 } = this.props.data

    const jobs = this.props.data.jobs.map((job) => {
      return (
        <Slide left duration={1300} triggerOnce="true" key={job.role}>
          <div className="jobs-container">
            <div className="job-box">
              <div className="box">
                {/* Left side */}
                <div className="left-box">
                  <div className="logo">
                    <img className="logo-icon" src={job.image} width="36" height="36" alt="job" />
                  </div>
                  <div className="text">
                    <div className="role">{job.role}</div>
                    <div>{job.details}</div>
                  </div>
                </div>
                {/* Right side */}
                <div className="right-box">
                  <div className="job-description">{job.description}</div>
                  <span className="badge">New</span>
                </div>
              </div>
            </div>
          </div>
        </Slide>
      )
    })

    return (
      <section id="career">
        <Slide left duration={1300} triggerOnce="true">
          <div className="row">
            <div className="header-col career-header">
              <h1>
                <span>Career Opportunities</span>
              </h1>
            </div>

            <div className="career-container">
              <div className="row">
                <div className="row item description">{part1}</div>
                <div className="row item description">{part2}</div>
              </div>
            </div>
          </div>
        </Slide>
        <div>{jobs}</div>
      </section>
    )
  }
}

export default Career
