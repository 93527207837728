import React, { Component } from "react"
import ParticlesBg from "particles-bg"
import Fade from "react-awesome-reveal"

class Header extends Component {
  render() {
    if (!this.props.data) return null

    //const project = this.props.data.project;
    const github = this.props.data.github
    const name = this.props.data.name
    const description = this.props.data.description

    return (
      <header id="home">
        <ParticlesBg type="circle" bg={true} />

        <nav id="nav-wrap">
          <a className="mobile-btn" href="#nav-wrap" title="Show navigation">
            Show navigation
          </a>
          <a className="mobile-btn" href="#home" title="Hide navigation">
            Hide navigation
          </a>

          <ul id="nav" className="nav">
            <li className="current">
              <a className="smoothscroll" href="#home">
                Home
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#about">
                About
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#services">
                Services
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#cases">
                Case Studies
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#career">
                Career
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#contactus">
                Contact us
              </a>
            </li>
          </ul>
        </nav>

        <div className="row banner">
          <div className="banner-text">
            <Fade bottom triggerOnce="true">
              <h1 className="responsive-headline">{name}</h1>
            </Fade>
            <Fade bottom duration={1200} triggerOnce="true">
              <h3>{description}.</h3>
            </Fade>
            <hr />
            <Fade bottom duration={2000} triggerOnce="true">
              <ul className="social">
                <li>
                  <a href="#cases" className="button btn project-btn">
                    <i className="fa fa-book"></i>Projects
                  </a>
                </li>
                <li>
                  <a href={github} target="_blank" rel="noopener noreferrer" className="button btn github-btn">
                    <i className="fa fa-github"></i>Github
                  </a>
                </li>
              </ul>
            </Fade>
          </div>
        </div>

        <p className="scrolldown">
          <a className="smoothscroll" href="#about">
            <i className="icon-down-circle"></i>
          </a>
        </p>
      </header>
    )
  }
}

export default Header
