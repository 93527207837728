import React, { Component } from "react"
import Slide from "react-awesome-reveal"

class Contactus extends Component {
  render() {
    if (!this.props.data) return null

    const { form, youtube } = this.props.data

    return (
      <section id="contactus">
        <Slide left duration={1300} triggerOnce="true">
          <div className="row">
            <div className="header-col contactus-header">
              <h1>
                <span>Get in touch</span>
              </h1>
            </div>

            <div className="contactus-container">
              <div className="row contactus-row">
                <p className="link-description">Contact us from the </p>
                <a className="link" href={form} target="_blank" rel="noopener noreferrer">
                  link
                </a>
              </div>
              <div className="row contactus-row">
                <p className="link-description">YouTube: </p>
                <a className="link" href={youtube} target="_blank" rel="noopener noreferrer">
                  澳洲程序员大卫
                </a>
              </div>
            </div>
          </div>
        </Slide>
      </section>
    )
  }
}

export default Contactus
