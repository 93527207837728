import React, { Component } from "react"
import Fade from "react-awesome-reveal"

class About extends Component {
  render() {
    if (!this.props.data) return null

    const profilepic = "images/cyberlark_logo.png"
    const { bio, mission, goal } = this.props.data

    return (
      <section id="about">
        <Fade duration={1000} triggerOnce="true">
          <div className="row">
            <div className="three columns">
              <div className="logo-container">
                <img src={profilepic} alt="Logo" />
              </div>
            </div>
            <div className="nine columns main-col">
              <h2>About Cyberlark</h2>
              <p>{bio}</p>
              <h2>Mission</h2>
              <p>{mission}</p>
              <h2>Goal</h2>
              <p>{goal}</p>
            </div>
          </div>
        </Fade>
      </section>
    )
  }
}

export default About
