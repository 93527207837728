import React, { Component } from "react"
import Slide from "react-awesome-reveal"

class Cases extends Component {
  render() {
    if (!this.props.data) return null

    const caseStudies = this.props.data.caseStudies

    const cases = caseStudies.map((caseItem) => {
      return (
        <Slide left duration={1300} triggerOnce="true" key={caseItem.name}>
          <div className="row caseItem">
            <div className="row item"></div>

            <div className="three columns header-col">
              <h3>
                <span>{caseItem.name}</span>
              </h3>
            </div>

            <div className="nine columns main-col">
              <div className="row item">
                <div className="twelve columns">
                  <img src={caseItem.image} alt="case study"></img>
                  <p className="description">{caseItem.description}</p>
                  <p className="additional">{caseItem.additional}</p>
                  <a className="link" href={caseItem.link} target="_blank" rel="noopener noreferrer">
                    {caseItem.link}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Slide>
      )
    })

    return (
      <section id="cases">
        <Slide left duration={1300} triggerOnce="true">
          <div className="row">
            <div className="header-col cases-header">
              <h1>
                <span>Case Studies</span>
              </h1>
            </div>
          </div>
        </Slide>
        <div className="case-container">{cases}</div>
      </section>
    )
  }
}

export default Cases
